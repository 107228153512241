 $(document).ready(function () {
    var url = window.location;
    $('ul.nav a[href="'+ url +'"]').parent().addClass('active');
    $('ul.nav a').filter(function() {
         return this.href == url;
    }).parent().addClass('active');
});
$(document).ready(function() {
            var width = $(window).width();
            if (width<=500) {
                var nav=false;
            }else{
                var nav=true;
            }
            $('#fullpage').fullpage( {
                scrollingSpeed: 700,
                menu: '#menu',
                lockAnchors: false,
                anchors:['main', 'about_firm', 'about_philosophy', 'about_name'],
                navigation: nav,
                navigationPosition: 'left',
                showActiveTooltip: false,
                slidesNavigation: false,
                slidesNavPosition: 'bottom',
                loopBottom: true,
            });
        });

$(document).on('click', '#moveTop', function(){
  $.fn.fullpage.moveTo('main');
});


$(document).on('click', '#moveDown', function(){
  $.fn.fullpage.moveSectionDown();
});

// make about nav active by switching about sections on the main page
$(window).on('hashchange', function(e){
 var url = window.location.href;   
	if(url.indexOf('about_firm')!=-1 || url.indexOf('about_philosophy')!=-1 || url.indexOf('about_name')!=-1){
		var target = $('li:contains("about")');
		target.addClass("active")
	}else{
		var target = $('li:contains("about")');
		target.removeClass("active")
	}
});
